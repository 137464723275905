export class Lesson {
    id: string;
    dayOfYear: string;
    numDayOfYear: number;
    monthDay: string;
    lessonTitle: string;
    quote: string;
    content: string;
    actionPoint: string;
    book: string;
}
