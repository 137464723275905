import { Injectable } from '@angular/core';
import { Lesson } from './lesson.model';
import { HttpClient } from '@angular/common/http';

const api = 'https://localhost:44327/api';

@Injectable({
  providedIn: 'root'
})
export class LessonService {
  constructor(private http: HttpClient) { }

  lessons: any = [];
  janLessons: any = [];


  getLessonsList() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsListAsync`);
  }

  getLesson(dayOfYear: string) {
    return this.http.get<Lesson>(`${api}/lessons/GetSingleItemAsync?dayOfYear=${dayOfYear}`);
  }

  updateLesson(lesson: Lesson) {
    return this.http.put<Lesson>(`${api}/lessons/UpdateLesson?id=${lesson.id}`, lesson);
  }


// Get Lessons by Month for expandable panels on Lessons List page

  getJanLessons() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsByMonthAsync?month=January`);
  }

  getFebLessons() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsByMonthAsync?month=February`);
  }

  getMarLessons() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsByMonthAsync?month=March`);
  }

  getAprLessons() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsByMonthAsync?month=April`);
  }

  getMayLessons() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsByMonthAsync?month=May`);
  }

  getJunLessons() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsByMonthAsync?month=June`);
  }

  getJulLessons() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsByMonthAsync?month=July`);
  }

  getAugLessons() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsByMonthAsync?month=August`);
  }

  getSepLessons() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsByMonthAsync?month=September`);
  }

  getOctLessons() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsByMonthAsync?month=October`);
  }

  getNovLessons() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsByMonthAsync?month=November`);
  }

  getDecLessons() {
    return this.http.get<Array<Lesson>>(`${api}/lessons/GetLessonsByMonthAsync?month=December`);
  }


  //   deleteLesson(lesson: Lesson) {  
  //     return this.http.delete(`${api}/lesson?uid=${lesson.uid}`);  
  //   }  

  //   addLesson(lesson: Lesson) {  
  //     return this.http.post<Lesson>(`${api}/lesson/`, lesson);  
  //   }  


}  