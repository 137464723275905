import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { Lesson } from '../shared/lesson.model'
import { LessonService } from '../shared/lesson.service'
import { EditorAlignCenterButtonDirective } from '@progress/kendo-angular-editor';

@Component({
  selector: 'lesson-edit',
  templateUrl: 'lesson-edit.component.html',
  styleUrls: ['../../app.component.css', 'lesson-edit.component.css'],
  providers: [LessonService]
})
export class LessonEditComponent implements OnInit {
  title: string = 'Drucker per Day';
  dayOfYear: string = this.route.snapshot.params['id'];
  dayOfYearNum: number = +this.route.snapshot.params['id'];
  lesson: Lesson;

    constructor(private router: Router, private route: ActivatedRoute, private lessonService: LessonService) {
    route.params.subscribe(val => this.ngOnInit())
  }

  ngOnInit() {
    this.lesson = new Lesson;  // Need to initialize Lesson model to avoid property problems on form
    this.fetchLesson();
  }

  fetchLesson() {
    return this.lessonService.getLesson(this.dayOfYear).subscribe(x => {
      this.lesson = x;
      //console.log(this.lesson);
    });

  }

  onSubmit() {
    this.save()
  }

  save() {
    this.lessonService.updateLesson(this.lesson).subscribe();  
    console.log(this.lesson);
    this.router.navigate(['/lessons/' + this.dayOfYearNum], {
      queryParams: { refresh: new Date().getTime() }
    });
  }

  cancel() {
    this.router.navigate(['/lessons/' + this.dayOfYearNum], {
      queryParams: { refresh: new Date().getTime() }
    });
  }
}
