import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IalignComponent } from './ialign/ialign.component';
import { LessonsListComponent } from './lessons/lessons-list/lessons-list.component';
import { LessonDetailsComponent } from './lessons/lesson-details/lesson-details.component';
import { LessonEditComponent } from './lessons/lesson-edit/lesson-edit.component';


const routes: Routes = [
    { path: "home", component: IalignComponent },
    { path: "list", component: LessonsListComponent },
    { path: "lessons/:id", component: LessonDetailsComponent },
    { path: "lesson-edit/:id", component: LessonEditComponent },
    { path: "", redirectTo : 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
