import { Component, Input } from '@angular/core';
import { Lesson } from './../shared/lesson.model'

@Component({
  selector: 'lesson-thumbnail',
  templateUrl: 'lesson-thumbnail.component.html',
  styleUrls:  ['./../../app.component.css']
})
export class LessonThumbnailComponent {
  @Input() lesson: Lesson;
  
}
