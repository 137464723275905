import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { Lesson } from './../shared/lesson.model'
import { LessonService } from './../shared/lesson.service'
// import { FirebaseService } from './../shared/firebase.service'
// import { FirebaseListObservable } from "angularfire2/database";

@Component({
  templateUrl: 'lessons-list.component.html',
  styleUrls: ['./../../app.component.css'],
  providers: [LessonService]
})
export class LessonsListComponent implements OnInit {
  title: string = 'Drucker per Day';
  dayOfTheYear: string;
  errorMessage: string;

  janLessons: Array<Lesson>;
  febLessons: Array<Lesson>;
  marLessons: Array<Lesson>;
  aprLessons: Array<Lesson>;
  mayLessons: Array<Lesson>;
  junLessons: Array<Lesson>;
  julLessons: Array<Lesson>;
  augLessons: Array<Lesson>;
  sepLessons: Array<Lesson>;
  octLessons: Array<Lesson>;
  novLessons: Array<Lesson>;
  decLessons: Array<Lesson>;

  constructor(private router: Router, private lessonService: LessonService) {

  }

  ngOnInit() {

    this.calcDayOfTheYear();

    this.lessonService.getJanLessons().subscribe(lessons => {
      this.janLessons = lessons
    });

    this.lessonService.getFebLessons().subscribe(lessons => {
      this.febLessons = lessons
    });

    this.lessonService.getMarLessons().subscribe(lessons => {
      this.marLessons = lessons
    });

    this.lessonService.getAprLessons().subscribe(lessons => {
      this.aprLessons = lessons
    });

    this.lessonService.getMayLessons().subscribe(lessons => {
      this.mayLessons = lessons
    });

    this.lessonService.getJunLessons().subscribe(lessons => {
      this.junLessons = lessons
    });

    this.lessonService.getJulLessons().subscribe(lessons => {
      this.julLessons = lessons
    });

    this.lessonService.getAugLessons().subscribe(lessons => {
      this.augLessons = lessons
    });

    this.lessonService.getSepLessons().subscribe(lessons => {
      this.sepLessons = lessons
    });

    this.lessonService.getOctLessons().subscribe(lessons => {
      this.octLessons = lessons
    });

    this.lessonService.getNovLessons().subscribe(lessons => {
      this.novLessons = lessons
    });

    this.lessonService.getDecLessons().subscribe(lessons => {
      this.decLessons = lessons
    });
  }

  calcDayOfTheYear() {
    let now: any = new Date();
    let start: any = new Date(now.getFullYear(), 0, 0);
    let diff = now - start;
    let oneDay = 1000 * 60 * 60 * 24;
    let day = Math.floor(diff / oneDay);
    this.dayOfTheYear = day.toString();
  }

  gotoTodaysLesson() {
    this.router.navigate(['/lessons/' + this.dayOfTheYear]);
  }
}
