import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';  

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LessonService } from './lessons/shared/lesson.service';

// Material 2 
import 'hammerjs';
import 'hammer-timejs';
import { MdCoreModule, OverlayModule, MdUniqueSelectionDispatcher } from '@angular2-material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MdButtonModule } from '@angular2-material/button';
import { MdCardModule } from '@angular2-material/card';
import { MdRadioModule } from '@angular2-material/radio';
import { MdCheckboxModule } from '@angular2-material/checkbox'
import { MdTooltipModule } from '@angular2-material/tooltip';
import { MdSliderModule } from '@angular2-material/slider';
import { MdToolbarModule } from '@angular2-material/toolbar';
import { MdIconModule, MdIconRegistry } from '@angular2-material/icon';
import { ExpansionPanelsModule } from 'ng2-expansion-panels';
import { IalignComponent } from './ialign/ialign.component';
import { LessonsListComponent } from './lessons/lessons-list/lessons-list.component';
import { LessonThumbnailComponent } from './lessons/lesson-thumbnails/lesson-thumbnail.component';
import { LessonDetailsComponent } from './lessons/lesson-details/lesson-details.component';
import { LessonEditComponent } from './lessons/lesson-edit/lesson-edit.component';
import { TopbarComponent } from './topbar/topbar.component';
import { EditorModule } from '@progress/kendo-angular-editor';



@NgModule({
  declarations: [
    AppComponent,
    IalignComponent,
    LessonsListComponent,
    LessonThumbnailComponent,
    LessonDetailsComponent,
    LessonEditComponent,
    TopbarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    MdCoreModule, 
    BrowserAnimationsModule,
    MdCardModule, MdButtonModule, MdRadioModule,
    MdCheckboxModule, MdTooltipModule, MdSliderModule, MdIconModule, MdToolbarModule, 
    ExpansionPanelsModule,
    OverlayModule.forRoot(),
    EditorModule
  ],
  providers: [
    MdUniqueSelectionDispatcher,
    MdIconRegistry,
    LessonService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
