import { Component } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  templateUrl: 'ialign.component.html',
  styleUrls: ['../app.component.css']
})
export class IalignComponent {

  constructor(private router: Router) {
  }

  Dpd() {
    this.router.navigate(['/list/']);
  }
}
