import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router'
import { Lesson } from './../shared/lesson.model'
import { LessonService } from './../shared/lesson.service'

@Component({
  selector: 'lesson-details',
  templateUrl: 'lesson-details.component.html',
  styleUrls: ['../../app.component.css'],
  providers: [ LessonService ]
})
export class LessonDetailsComponent implements OnInit {
  title: string = 'Drucker per Day';
  dayOfYear: string = this.route.snapshot.params['id'];
  dayOfYearNum: number = +this.route.snapshot.params['id'];

  constructor(private router: Router, private route: ActivatedRoute, private lessonService: LessonService) {
    route.params.subscribe(val => this.ngOnInit())
  }
  
  lesson: Lesson;


  ngOnInit() {
    this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      const refresh = paramMap.get('refresh');
      if (refresh) {
        this.fetchLesson();
      }
    });

    
  }

  fetchLesson() {
    return this.lessonService.getLesson(this.dayOfYear).subscribe(x => {  
      this.lesson = x });
     console.log(this.lesson);
  }

  swipeLeft() {
    this.dayOfYearNum = this.dayOfYearNum+1;
    this.dayOfYear=this.dayOfYearNum.toString();

    this.router.navigate(['/lessons/' + this.dayOfYearNum], {
      queryParams: {refresh: new Date().getTime()}
    });
  }

  swipeRight() {
    this.dayOfYearNum = this.dayOfYearNum-1;
    this.dayOfYear=this.dayOfYearNum.toString();
    
    this.router.navigate(['/lessons/' + this.dayOfYearNum], {
      queryParams: {refresh: new Date().getTime()}
    });
  }

  edit() {
    this.router.navigate(['/lesson-edit/' + this.dayOfYear])
  }

}
